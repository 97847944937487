import autoimage from './Images/autownlogoblack.jpg'
import cimage from './Images/colabimage5.png'
import './Styling/Overview.css'
import trpohy from './Images/trophy-icon-5.jpg'

function Overview() {



    return (
        <>
            <div className="overview" id='overview'>
            <h2 className='orbitron'><span className='orbitron' style={{ color: '#07FCAF', fontWeight:700}}>AUTO</span>wn<span className='orbitron'>'24</span></h2>
                <div className="main">
                    {/* <div className="collab"><p>In collaboration with</p><img className="collabimg" src={cimage}></img><p>Presents</p><img className="collabimg2" src={autoimage}></img></div> */}
                    <div className="overview-text">
                        <p className='line-spacing'>Join us at WILP AUTOwn'24, proudly showcasing the theme "MADE - Material Technologies and Advanced Materials, ADAS, and Electric Vehicles." This symposium serves as a dynamic platform where industry leaders unveil their latest innovations and collaborate with some of the brightest students in the field.

Embracing the spirit of MADE, participants will dive into exciting competitions across Material technologies, ADAS Systems and Electric Vehicle Advancements. AUTOwn'24 is not just an event; it's a movement where industry excellence meets student creativity to shape the future of technology.

Explore new horizons, collaborate on revolutionary ideas, and lead the charge in the MADE revolution at BITS AUTOwn'24. Be part of a community that innovates, educates, and transforms.
  <br></br><br></br> 
                        Participation Open to All.<br></br>Exicting Cash Prize for the Winners !</p>

                        {/* <h2>Objective of the Event:</h2>
                        <ol>
                            <li>To familiarize students with emerging technologies and solicit their innovative solutions to address the challenges currently encountered in our laboratories.
                            </li>
                            <li>To increase student awareness of current trends and industry requirements by facilitating participation in question-and-answer sessions with technical experts.
                            </li>
                            <li>To offer external students the opportunity to collaborate with BITS faculty and utilize our facilities to pursue their interests.
                            </li>
                        </ol> */}

                        <div className="cashprize">
                            <img src={trpohy}></img>
                            <div className='cash'>₹ 1,35,000</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Overview;